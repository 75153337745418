import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import { Warning, Search } from '@mui/icons-material';

const App = () => {
  return (
    <Container maxWidth="md" sx={{ textAlign: 'center' }}>
      <Box
        mt={4}
        className="column"
        sx={{
          backgroundColor: 'white',
          borderRadius: '16px',
          boxShadow: 5,
          borderTop: '12px solid',
          borderTopColor: '#fe6b00',
          overflow: 'hidden',
        }}
      >
        <img
          src="/images/BannerFormulario.png"
          alt="Banner"
          style={{ width: '100%' }}
        />
        <Box
          sx={{
            padding: '24px',
          }}
        >
          <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
            <Warning sx={{ fontSize: 40, color: '#fe6b00', mr: 1 }} />
            <Typography variant="h4" color="textPrimary">
              Postulaciones Cerradas
            </Typography>
          </Box>

          <Typography variant="body1" color="textSecondary" paragraph>
            Las postulaciones para el programa <strong>Regalos de Navidad 2024</strong> han finalizado.
            Agradecemos tu interés en participar.
          </Typography>

          <Typography variant="body1" color="textSecondary" paragraph>
            Si deseas realizar consultas sobre tu postulación, haz clic en el siguiente enlace:
          </Typography>

          <Box mt={4} display="flex" justifyContent="center">
            <a
              href="https://navidadloprado.cl/consultanavidad/"
              style={{
                textDecoration: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#fe6b00',
                color: '#fff',
                padding: '12px 24px',
                borderRadius: '8px',
                fontWeight: 'bold',
                fontSize: '16px',
                boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                transition: 'background-color 0.3s ease',
              }}
              onMouseOver={(e) => (e.target.style.backgroundColor = '#ff8c33')}
              onMouseOut={(e) => (e.target.style.backgroundColor = '#fe6b00')}
            >
              <Search sx={{ mr: 1 }} />
              Ir a la página de consulta
            </a>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default App;
